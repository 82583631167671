import { ActionTypes } from "./types";
import { toast } from "react-toastify";
import Api from "../../api/Api";

export const loadBlog = () => (dispatch, getState) => {
  Api.get("/blog/all")
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAD_BLOG,
        payload: response.data,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const addBlog = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOADING_BLOG });
  let req = Api.post("/blog/create", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_BLOG_FALSE });
        dispatch({
          type: ActionTypes.ADD_BLOG,
          payload: data.data,
        });
        return "The Blog has been Added";
      },
    },
    error: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_BLOG_FALSE });
        return data.response.data.message;
      },
    },
  });
};

export const updateBlog = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOADING_BLOG });

  let req = Api.post("/blog/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_BLOG,
          payload: data.data,
        });
        dispatch({ type: ActionTypes.LOADING_BLOG_FALSE });
        return "The Blog has been updated";
      },
    },
    error: {
      render({ data }) {
        dispatch({ type: ActionTypes.LOADING_BLOG_FALSE });
        return data.response.data.message;
      },
    },
  });
};

export const deleteBlog = (data) => (dispatch, getState) => {
  let req = Api.post("/blog/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render() {
        dispatch({
          type: ActionTypes.DELETE_BLOG,
          payload: data,
        });
        return "The Blog has Been Deleted";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const changeBlogState = (data) => (dispatch, getState) => {
  let req = Api.post("/blog/state", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_BLOG,
          payload: data.data.data,
        });
        return "The Blog State Has Been Updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const addBlogCategory = (data) => (dispatch, getState) => {
  let req = Api.post("/blog/category/create", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.ADD_BLOG_CATEGORY,
          payload: data.data,
        });
        return "The Category has been Added ";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const updateBlogCategory = (data) => (dispatch, getState) => {
  let req = Api.post("/blog/category/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.UPDATE_BLOG_CATEGORY,
          payload: data.data,
        });
        return "The Category has been updated";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};

export const deleteBlogCategory = (data) => (dispatch, getState) => {
  let req = Api.post("/blog/category/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          type: ActionTypes.DELETE_BLOG_CATEGORY,
          payload: data.data,
        });
        return "The Category has been Deleted";
      },
    },
    error: {
      render({ data }) {
        return data.response.data.message;
      },
    },
  });
};
