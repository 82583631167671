import React, { useState } from "react";
import { useSelector } from "react-redux";
import NavbarSection from "./elements/Navbar";
import { Link } from "react-router-dom";
import Footer from "./elements/Footer";
import { useTranslation } from "react-i18next";

export default function SyrianMenu() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const data = useSelector((state) => state.auth.public);
  const { t, i18n } = useTranslation();

  // Filter products based on the selected category and language
  const filteredProducts =
    selectedCategory === "All"
      ? data?.items.filter((product) => product.lang === i18n.language) // Filter by language
      : data?.items.filter(
          (product) =>
            product.category_id === selectedCategory &&
            product.lang === i18n.language // Filter by category and language
        );

  return (
    <>
      <NavbarSection data={data} />

      <div className="pagebanner">
        <div className="overlay"></div>
        <h1>{t("Syria menu")}</h1>
      </div>

      {filteredProducts ? (
        <section id="menu" className="menu-products py-5">
          <div className="bg2"></div>
          <div className="container">
            {/* Category Filter Buttons */}
            <div className="filter-buttons mb-5">
              {/* All Button */}
              <button
                key="all"
                className={`btn btn-primary w-100 rounded-pill filter-btn ${
                  selectedCategory === "All" ? "active" : ""
                }`}
                onClick={() => setSelectedCategory("All")}
              >
                All
              </button>

              {/* Category Buttons, filtered by language */}
              {data?.categories
                .filter((category) => category.lang === i18n.language) // Filter categories by language
                .map((category) => (
                  <button
                    key={category.id}
                    className={`btn btn-primary w-100 rounded-pill filter-btn ${
                      selectedCategory === category.id ? "active" : ""
                    }`}
                    onClick={() => setSelectedCategory(category.id)}
                  >
                    {category.name}
                  </button>
                ))}
            </div>

            {/* Products Grid */}
            <div className="row">
              {filteredProducts.map((product) => (
                <Link
                  to={{
                    pathname: "/product/" + product.id,
                    state: { productData: product, data: data },
                  }}
                  key={product.id}
                  className="col-md-4 mb-4"
                >
                  <div className="card h-100 border-0 shadow-sm">
                    <img
                      src={`https://api.baderaldeensweets.com/public/storage/${product.image}`}
                      className="card-img-top"
                      alt={product.name}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{product.name}</h5>
                      <p className="card-text text-primary fw-bold">
                        {product.price} OMR
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>
      ) : (
        <div className="text-center py-5">
          <p>Loading products...</p>
        </div>
      )}

      <Footer />
    </>
  );
}
