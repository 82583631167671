import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import NavbarSection from "./elements/Navbar";
import Footer from "./elements/Footer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import { useTranslation } from "react-i18next";

export default function News() {
  const [posts, setPosts] = useState(null);
  const data = useSelector((state) => state.auth.public);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    Api.get("/blog/all")
      .then((response) => {
        setPosts(response.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  return (
    <>
      <NavbarSection data={data} />

      <motion.div
        className="pagebanner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="overlay"></div>
        <h1>{t("News & Events")}</h1>
      </motion.div>

      <div className="container py-5">
        {posts !== null ? (
          posts.blog
            .filter((post) => post.language === i18n.language) // Filter posts by language
            .map((post) => (
              <motion.div
                className="news-section align-items-center mb-5"
                key={post.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="img">
                  <img
                    src={
                      "https://api.baderaldeensweets.com/public/storage/" +
                      post.image
                    }
                    alt={post.title}
                    className="img-fluid rounded"
                  />
                </div>
                <div className="card">
                  <div className="card-body">
                    <h2 className="h1 mb-4 text-primary">{post.title}</h2>
                    <div
                      className="lead text-muted mb-4"
                      dangerouslySetInnerHTML={{ __html: post.page_content }}
                    ></div>
                  </div>
                </div>
              </motion.div>
            ))
        ) : (
          <div className="text-center py-5">
            <p>Loading posts...</p>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
}
