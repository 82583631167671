import React from "react";
import "./App.css";
import AppRoutes from "./AppRouters";

import { withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { loadUser } from "./state/action-creator/auth-actions";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useTranslation } from "react-i18next";
import "./i18n";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit,
  FilePondPluginImageCrop
);

const App = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (state.auth.token) {
      dispatch(loadUser());
    }
  }, []);

  return (
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <AppRoutes />
      <ToastContainer />
    </ToastProvider>
  );
};

export default withRouter(App);
