import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import NavbarSection from "./elements/Navbar";
import { Link } from "react-router-dom";
import Footer from "./elements/Footer";
import { addToCart } from "../../state/action-creator/cart_actions";
import { useTranslation } from "react-i18next";

export default function AmmanMenu() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const data = useSelector((state) => state.auth.public);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Filter products based on selected category and language
  const filteredProducts =
    selectedCategory === "All"
      ? data?.items.filter((product) => product.lang === i18n.language) // Filter by language
      : data?.items.filter(
          (product) =>
            product.category_id === selectedCategory &&
            product.lang === i18n.language // Filter by category and language
        );

  // Handle adding an item to the cart
  const handleAddToCart = (product) => {
    const itemData = {
      id: product.id,
      item: product,
      howMany: 1,
      finalPrice: product.price,
    };

    dispatch(addToCart(itemData));
  };

  return (
    <>
      <NavbarSection data={data} />

      <motion.div
        className="pagebanner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="overlay"></div>
        <h1>{t("Amman menu")}</h1>
      </motion.div>

      {filteredProducts ? (
        <motion.section
          id="menu"
          className="menu-products py-5"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="bg2"></div>
          <div className="container">
            {/* Filter Buttons */}
            <div className="filter-buttons mb-5">
              {[
                "All",
                ...data?.categories.filter(
                  (category) => category.lang === i18n.language
                ),
              ].map((category) => (
                <motion.button
                  key={category.id || "all"}
                  className={`btn btn-primary w-100 rounded-pill filter-btn ${
                    selectedCategory === (category.id || "All") ? "active" : ""
                  }`}
                  onClick={() => setSelectedCategory(category.id || "All")}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {category.name || t("All")}
                </motion.button>
              ))}
            </div>

            {/* Products Grid */}
            <div className="row">
              {filteredProducts.map((product) => (
                <motion.div
                  key={product.id}
                  className="col-md-4 mb-4"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="card h-100 border-0 shadow-sm">
                    <Link
                      to={{
                        pathname: `/product/${product.id}`,
                        state: { productData: product, data: data },
                      }}
                    >
                      <img
                        src={`https://api.baderaldeensweets.com/public/storage/${product.image}`}
                        className="card-img-top"
                        alt={product.name}
                      />
                    </Link>
                    <div className="card-body">
                      <Link
                        to={{
                          pathname: `/product/${product.id}`,
                          state: { productData: product, data: data },
                        }}
                      >
                        <h5 className="card-title">{product.name}</h5>
                        <p className="card-text text-primary fw-bold">
                          {product.price} OMR
                        </p>
                      </Link>
                      <motion.button
                        onClick={() => handleAddToCart(product)}
                        className="btn btn-primary w-100 rounded-pill mt-3"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <i className="mdi mdi-cart me-2"></i>
                        {t("Order now")}
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.section>
      ) : (
        <div className="text-center py-5">
          <p>Loading products...</p>
        </div>
      )}

      <Footer />
    </>
  );
}
