import { ActionTypes } from "../action-creator/types";

const reducer = (
  state = { cart: [], checkoutSucceeded: false, succeededOrder: {} },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOAD_CART:
      let data = JSON.parse(localStorage.getItem("cart"));
      return data ? data : { ...state, cart: [] };

    case ActionTypes.ADD_CART:
      return { ...state, cart: [...state.cart, action.payload] };

    case ActionTypes.UPDATE_CART:
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    case ActionTypes.REMOVE_CART:
      const index = state.cart.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        // Item found, remove it
        return {
          ...state,
          cart: [...state.cart.slice(0, index), ...state.cart.slice(index + 1)],
        };
      }
      return state;

    case ActionTypes.CLEAR_CART:
      localStorage.removeItem("cart");
      return {
        ...state,
        cart: [],
      };
    case ActionTypes.CHECKOUT_SUCCEEDED:
      localStorage.removeItem("cart");
      return {
        ...state,
        cart: [],
        checkoutSucceeded: true,
        succeededOrder: action.payload,
      };
    case ActionTypes.CHECKOUT_SUCCEEDED_FALSE:
      return {
        ...state,
        checkoutSucceeded: false,
        succeededOrder: {},
      };
    default:
      return state;
  }
};

export default reducer;
