import React from "react";
import { useSelector } from "react-redux";
import NavbarSection from "./elements/Navbar";
import Footer from "./elements/Footer";

export default function About() {
  const data = useSelector((state) => state.auth.public);

  return (
    <>
      <NavbarSection data={data} />

      <div className="pagebanner">
        <div className="overlay"></div>

        <h1>About Us</h1>
      </div>

      <div className="container py-5">
        <div className="row py-5">
          <div className="col-md-6 text-center">
            <img
              src={
                "https://api.baderaldeensweets.com/public/storage/" +
                data?.data.firstsectionimage
              }
              width="100%"
            />
          </div>
          <div className="col-md-6 ">
            <h2 class="h1 mb-4 text-primary">{data?.data.aboutustitle}</h2>
            <p class="lead text-muted mb-4">{data?.data.aboutusdesc}</p>
          </div>
        </div>

        <div className="text-center">
          <h2 class="h1 mb-4 text-primary">Our Mission</h2>
          <p class="lead text-muted mb-4">{data?.data.aboutusdesc}</p>
        </div>
      </div>

      <div
        className="footer-line"
        style={{
          backgroundImage:
            "url(https://api.baderaldeensweets.com/public/storage/footer.png)",
        }}
      ></div>

      <Footer />
    </>
  );
}
