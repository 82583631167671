import React from "react";
import { useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiCartOutline } from "@mdi/js";
import CartModel from "./CartModel";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const data = useSelector((state) => state.auth.public);
  const state = useSelector((state) => state);
  const [showCart, setShowCart] = React.useState(false);

  const country = useSelector((state) => state.auth.country);
  const { t, i18n } = useTranslation();

  const handleShowCart = () => {
    setShowCart(!showCart);
  };

  return (
    <div className="container">
      {country !== null && country !== "SY" ? (
        <div className="cart-button" onClick={handleShowCart}>
          <Icon path={mdiCartOutline} size={"30px"} color="#fff" />
          <span className="cart-number-item">{state.cart.cart.length}</span>
        </div>
      ) : null}

      {showCart ? (
        <CartModel show={showCart} handleClose={setShowCart} data={data} />
      ) : null}

      <div className="footer">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-2 align-content-center">
                <i className="mdi mdi-phone"></i>
              </div>
              <div className="col-10">
                <div className="phones">
                  <a href="">
                    {i18n.language === "en"
                      ? data?.data.phone
                      : data?.data.phone_ar}
                  </a>
                  <a href="">
                    {i18n.language === "en"
                      ? data?.data.phone2
                      : data?.data.phone2_ar}
                  </a>
                  <a href="">
                    {i18n.language === "en"
                      ? data?.data.phone3
                      : data?.data.phone3_ar}
                  </a>
                  <a href="">
                    {i18n.language === "en"
                      ? data?.data.phone4
                      : data?.data.phone4_ar}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-2 align-content-center">
                <i className="mdi mdi-map"></i>
              </div>
              <div className="col-10">
                <p>
                  <a href="">{data?.data.address_ar}</a>
                </p>
                <p>
                  <a href="">{data?.data.address}</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="copyright">{t("Copyright © 2024 - Bader Al-Dein")} </p>
      </div>
    </div>
  );
}
