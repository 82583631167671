import { ActionTypes } from "../action-creator/types";

const reducer = (
  state = {
    pages: [],

    images: [],
    loading: false,
    errors: false,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOADING_PAGES:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.LOADING_PAGES_FALSE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.ERRORS_PAGES:
      return {
        ...state,
        errors: true,
      };
    case ActionTypes.ERRORS_PAGES_FALSE:
      return {
        ...state,
        errors: false,
      };
    case ActionTypes.LOAD_PAGES:
      return {
        ...state,
        // pages: action.payload.pages.map((item) => ({ ...item, image: action.payload.images.find((x) => x.id == item.image)?.image })),
        pages: action.payload.pages,

        images: action.payload.images,
      };
    case ActionTypes.ADD_PAGE:
      return {
        ...state,
        pages: [...state.pages, action.payload],
      };
    case ActionTypes.UPDATE_PAGE:
      return {
        ...state,
        pages: state.pages.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case ActionTypes.DELETE_PAGE:
      return {
        ...state,
        pages: state.pages.filter((item) => item.id === action.payload),
      };
    case ActionTypes.UPDATE_PAGES:
      return {
        ...state,
        pages: state.pages.map((item) =>
          action.payload.ids.includes(item.item_id)
            ? { ...item, stock_state: action.payload.stock_state }
            : item
        ),
      };

    default:
      return state;
  }
};

export default reducer;
