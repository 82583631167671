import { toast } from "react-toastify";
import { ActionTypes } from "./types";

export const addToCart = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.ADD_CART, payload: data });
  localStorage.setItem("cart", JSON.stringify(getState().cart));

  toast.success("The item has been added to your cart.", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const updateTheCart = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.UPDATE_CART, payload: data });
  localStorage.setItem("cart", JSON.stringify(getState().cart));
};

export const removeFromCart = (data) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.REMOVE_CART, payload: data });
  localStorage.setItem("cart", JSON.stringify(getState().cart));
};
