import { ActionTypes } from "../action-creator/types";

const reducer = (
  state = {
    blog: [],

    categories: [],
    images: [],
    loading: false,
    errors: false,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.LOADING_BLOG:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.LOADING_BLOG_FALSE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.ERRORS_BLOG:
      return {
        ...state,
        errors: true,
      };
    case ActionTypes.ERRORS_BLOG_FALSE:
      return {
        ...state,
        errors: false,
      };
    case ActionTypes.LOAD_BLOG:
      return {
        ...state,
        // blog: action.payload.blog.map((item) => ({ ...item, image: action.payload.images.find((x) => x.id == item.image)?.image })),
        blog: action.payload.blog,
        categories: action.payload.categories,

        images: action.payload.images,
      };
    case ActionTypes.ADD_BLOG:
      return {
        ...state,
        blog: [...state.blog, action.payload],
      };
    case ActionTypes.UPDATE_BLOG:
      return {
        ...state,
        blog: state.blog.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case ActionTypes.DELETE_BLOG:
      return {
        ...state,
        blog: state.blog.filter((item) => item.id === action.payload),
      };
    case ActionTypes.UPDATE_BLOG:
      return {
        ...state,
        blog: state.blog.map((item) =>
          action.payload.ids.includes(item.item_id)
            ? { ...item, stock_state: action.payload.stock_state }
            : item
        ),
      };
    case ActionTypes.ADD_BLOG_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };

    case ActionTypes.UPDATE_BLOG_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === action.payload.id ? action.payload : category
        ),
      };

    case ActionTypes.DELETE_BLOG_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default reducer;
