import { mdiMinus, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import api from "../../../api/Api";
import {
  addToCart,
  updateTheCart,
} from "../../../state/action-creator/cart_actions";

export default function AddToCart({
  show,
  handleClose,
  data,
  username,
  prevselectedGroups = {},
  prevselectedSize = "",
  prevhowMany = 1,
  prevNote = "",
  update = null,
}) {
  const [isLoading, setIsLoading] = React.useState(true);

  const [selectedSize, setSelectedSize] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [note, setNote] = React.useState();

  const [groups, setGroups] = React.useState([]);

  const [howMany, setHowMany] = React.useState(1);
  const [sizePrice, setSizePrice] = React.useState(0);
  const [groupPrice, setGroupPrice] = React.useState(0);
  const [finalPrice, setFinalPrice] = React.useState(1);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const calculatePrice = (groups) => {
    groups.map((group) =>
      JSON.parse(group.addons).map((addon) =>
        prevselectedGroups[group.id]?.includes(`${addon.id}`)
          ? setGroupPrice((groupPrice) => groupPrice + parseInt(addon.price))
          : null
      )
    );

    JSON.parse(data.sizes).map((size) =>
      size.id == prevselectedSize
        ? setSizePrice((sizePrice) => size.sizeprice)
        : null
    );
  };

  React.useEffect(() => {
    setIsLoading(() => true);
    setFinalPrice(data.price);

    api
      .post("/order/groups", {
        username: username,
        groups: data.groups,
      })
      .then((response) => {
        setGroups(response.data);

        setHowMany(prevhowMany);
        setSelectedSize(prevselectedSize);
        setSelectedGroups(prevselectedGroups);
        setNote(prevNote);

        calculatePrice(response.data);

        setIsLoading(() => false);
      })
      .catch((error) => console.log(error));
  }, []);

  React.useEffect(() => {
    setFinalPrice((data.price + parseInt(sizePrice) + groupPrice) * howMany);
  }, [howMany, sizePrice, groupPrice]);

  const handleGroupChange = (event, id) => {
    if (event.target.checked) {
      setGroupPrice(groupPrice + parseInt(event.target.value));
    } else {
      setGroupPrice(groupPrice - parseInt(event.target.value));
    }

    handleGroupsChange(event, id);
  };

  const handleGroupsChange = (event, id) => {
    let value = event.target.id;

    if (!selectedGroups[id]) {
      setSelectedGroups({ ...selectedGroups, [id]: [value] });
      return;
    }

    if (selectedGroups[id]?.includes(value)) {
      let newArray = { [id]: [...selectedGroups[id], value] };

      setSelectedGroups({
        ...selectedGroups,
        [id]: newArray[id].filter((item) => item !== event.target.id),
      });
    } else {
      setSelectedGroups({
        ...selectedGroups,
        [id]: [...selectedGroups[id], value],
      });
    }
  };

  const handleRadioChange = (event) => {
    setSelectedSize(event.target.id);
    setSizePrice(event.target.value);
  };

  const handleAddToCart = () => {
    let itemdata = {
      id: update ? data.id : state.cart.cart.length + 1,
      item: data,
      selectedSize: selectedSize,
      selectedGroups: selectedGroups,
      howMany: howMany,
      finalPrice: finalPrice,
      note: note,
      username: username,
    };

    update ? dispatch(updateTheCart(itemdata)) : dispatch(addToCart(itemdata));

    handleClose();
  };

  return (
    <Modal
      className="modal-add-to-cart"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header
        closeButton
        style={{
          background: `url(${
            process.env.REACT_APP_PUBLIC_URL + "/storage/" + data.image
          })`,
        }}
      ></Modal.Header>
      <Modal.Body>
        <h4>{data.name}</h4>
        <p className="card-text">{data.description}</p>

        {!isLoading ? (
          <>
            <Form.Group className="mb-4 groupbox">
              <label htmlFor="size" className="groupbox-title">
                Sizes
              </label>

              {JSON.parse(data.sizes).map((size) =>
                size.id == prevselectedSize ? (
                  <div className="form-check form-check-dark" key={size.id}>
                    <label className="form-check-label">
                      <input
                        type="radio"
                        className="form-check-input"
                        defaultChecked
                        name="size"
                        id={size.id}
                        value={size.sizeprice}
                        onChange={handleRadioChange}
                      />
                      {size.size} {"$" + size.sizeprice}
                      <i className="input-helper"></i>
                    </label>
                  </div>
                ) : (
                  <div className="form-check form-check-dark" key={size.id}>
                    <label className="form-check-label">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="size"
                        id={size.id}
                        value={size.sizeprice}
                        onChange={handleRadioChange}
                      />
                      {size.size} {size.sizeprice ? "$" + size.sizeprice : null}
                      <i className="input-helper"></i>
                    </label>
                  </div>
                )
              )}
            </Form.Group>

            {groups.map((group) => (
              <Form.Group className="mb-4 groupbox" key={group.id}>
                <label htmlFor="size" className="groupbox-title">
                  {group.name}
                </label>

                {JSON.parse(group.addons).map((addon) =>
                  prevselectedGroups[group.id]?.includes(`${addon.id}`) ? (
                    <div className="form-check form-check-dark" key={addon.id}>
                      <label className="form-check-label">
                        vdvdvdv
                        <input
                          type="checkbox"
                          className="form-check-input"
                          defaultChecked
                          id={addon.id}
                          value={addon.price}
                          onChange={(event) =>
                            handleGroupChange(event, group.id)
                          }
                        />{" "}
                        {addon.addon}
                        <i className="input-helper"></i>
                      </label>
                      <span className="price">{"$" + addon.price}</span>
                    </div>
                  ) : (
                    <div className="form-check form-check-dark" key={addon.id}>
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={addon.id}
                          value={addon.price}
                          onChange={(event) =>
                            handleGroupChange(event, group.id)
                          }
                        />{" "}
                        {addon.addon}
                        <i className="input-helper"></i>
                      </label>
                      <span className="price">
                        {addon.price ? "$" + addon.price : null}
                      </span>
                    </div>
                  )
                )}
              </Form.Group>
            ))}

            <Form.Group className="mb-4 groupbox">
              <label htmlFor="note" className="groupbox-title">
                Add a note
              </label>
              <textarea
                name="note"
                id="note"
                className="form-control"
                placeholder="Add a note"
                onChange={(e) => setNote(e.target.value)}
                value={note}
              ></textarea>
            </Form.Group>

            <div className="add-to-cart">
              <div className="quantity">
                <button onClick={() => setHowMany(howMany + 1)}>
                  <Icon path={mdiPlus} size={"24"} color="#fff" />
                </button>
                <span>{howMany}</span>
                <button
                  onClick={() =>
                    howMany >= 2 ? setHowMany(howMany - 1) : null
                  }
                >
                  <Icon path={mdiMinus} size={"24"} color="#fff" />
                </button>
              </div>
              <span className="price">{"$" + finalPrice}</span>
              {data.stock_state ? (
                <button className="add" onClick={handleAddToCart}>
                  {update ? "Update Item" : "Add To Cart"}
                </button>
              ) : (
                <button
                  className="add"
                  disabled={true}
                  style={{ opacity: 0.5 }}
                >
                  Out Of Stock
                </button>
              )}
            </div>
          </>
        ) : (
          <div
            className="spinner-border"
            role="status"
            style={{ margin: "100px auto", display: "block" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
