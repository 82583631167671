import React, { useState } from "react";
import Api from "../../api/Api";
import NavbarSection from "./elements/Navbar";
import Footer from "./elements/Footer";
import { useSelector } from "react-redux";

export default function Page(props) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);
  const data = useSelector((state) => state.auth.public);

  React.useEffect(() => {
    Api.get("/page/" + props.match.params.slug)
      .then((res) => {
        let content = res.data.page_content;

        // Dynamically process <code> tags
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");

        doc.querySelectorAll("code").forEach((code) => {
          const unescapedHTML = code.textContent; // Get the inner escaped content
          const div = document.createElement("div");
          div.innerHTML = unescapedHTML; // Convert it to actual HTML
          code.replaceWith(...div.childNodes); // Replace <code> with the actual HTML
        });

        setPage({ ...res.data, page_content: doc.body.innerHTML });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [props.match.params.slug]);

  return !loading ? (
    page !== null ? (
      <>
        <NavbarSection data={data} />

        <div className="pagebanner">
          <div className="overlay"></div>
          <h1>{page.title}</h1>
        </div>

        <div className="container py-5 single-page">
          <div
            className="lead text-muted mb-4"
            dangerouslySetInnerHTML={{ __html: page.page_content }}
          ></div>
        </div>

        <Footer />
      </>
    ) : null
  ) : null;
}
